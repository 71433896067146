export enum RoutePaths {
  Root = '/',
  SignIn = '/sign-in',

  Events = '/events',
  EventsNew = '/events/new',
  EventsIdEdit = '/events/:id/edit',

  DailyQuestions = '/daily-questions',
  DailyQuestionNew = '/daily-questions/new',
  DailyQuestionEdit = '/daily-questions/:id/edit',

  Leaderboard = '/leaderboard',

  InvitationAlias = '/r/:code',
  DownloadApp = '/download-app',
}
