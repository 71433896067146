import { lazy, Suspense, useCallback, useMemo } from 'react'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom'

import { RoutePaths } from '@/enums'

import { createDeepPath } from './helpers'
import AuthLayout from './layouts/AuthLayout'
import MainLayout from './layouts/MainLayout'
import { useAuthState } from './store'

export const AppRoutes = () => {
  const { accessToken } = useAuthState()

  const InvitationAlias = lazy(() => import('@/pages/InvitationAlias'))
  const DownloadApp = lazy(() => import('@/pages/DownloadApp'))
  const SignIn = lazy(() => import('@/pages/SignIn'))
  const Events = lazy(() => import('@/pages/Events'))
  const DailyQuestions = lazy(() => import('@/pages/DailyQuestions'))
  const Leaderboard = lazy(() => import('@/pages/Leaderboard'))

  const isAuthorized = useMemo(() => !!accessToken, [accessToken])

  const signInGuard = useCallback(
    ({ request }: LoaderFunctionArgs) => {
      const requestUrl = new URL(request.url)
      const from = requestUrl.searchParams.get('from')

      return isAuthorized ? redirect(from ? `${from}${requestUrl.search}` : RoutePaths.Root) : null
    },
    [isAuthorized],
  )
  const authProtectedGuard = useCallback(
    ({ request }: LoaderFunctionArgs) => {
      // If the user is not logged in and tries to access protected route, we redirect
      // them to sign in with a `from` parameter that allows login to redirect back
      // to this page upon successful authentication
      if (!isAuthorized) {
        const requestUrl = new URL(request.url)
        requestUrl.searchParams.set('from', requestUrl.pathname)

        return redirect(`${RoutePaths.SignIn}${requestUrl.search}`)
      }

      return null
    },
    [isAuthorized],
  )

  const LayoutComponent = useMemo(() => {
    return isAuthorized ? MainLayout : AuthLayout
  }, [isAuthorized])

  const router = createBrowserRouter([
    {
      path: RoutePaths.Root,
      element: (
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          element: (
            <LayoutComponent>
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </LayoutComponent>
          ),
          children: [
            {
              path: createDeepPath(RoutePaths.Events),
              loader: authProtectedGuard,
              element: <Events />,
            },
            {
              path: createDeepPath(RoutePaths.DailyQuestions),
              loader: authProtectedGuard,
              element: <DailyQuestions />,
            },
            {
              path: createDeepPath(RoutePaths.Leaderboard),
              loader: authProtectedGuard,
              element: <Leaderboard />,
            },
            {
              path: createDeepPath(RoutePaths.SignIn),
              loader: signInGuard,
              element: <SignIn />,
            },
            {
              path: RoutePaths.Root,
              element: <Navigate replace to={RoutePaths.DownloadApp} />,
            },
            {
              path: '*',
              element: <Navigate replace to={RoutePaths.Root} />,
            },
          ],
        },
        {
          path: RoutePaths.InvitationAlias,
          element: <InvitationAlias />,
        },
        {
          path: RoutePaths.DownloadApp,
          element: <DownloadApp />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
